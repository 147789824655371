import { Component, OnInit, Input } from '@angular/core';
import { ModalController, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { NavParamsService } from 'src/app/nav-params.service';
import config from 'src/config';
import axios from 'axios';

@Component({
  selector: 'app-invite-users',
  templateUrl: './invite-users.page.html',
  styleUrls: ['./invite-users.page.scss'],
})
export class InviteUsersPage implements OnInit {
  public attendees: any = this.navParams.getNavParams().attendees;
  public attendeeIds = [];
  public employees: any = [];
  public hasSearched = false;
  public isLoading = false;
  public employeeCode = '';
  public firstName = '';
  public lastName = '';
  public invitedUsers = [];

  constructor(
    public modalCtrl: ModalController,
    public storage: Storage,
    public alertCtrl: AlertController,
    public navParams: NavParamsService
  ) {
    console.log(this.attendees);
    if (this.attendees !== null && this.attendees !== undefined) {
      this.attendees.forEach(attendee => {
        this.attendeeIds.push(attendee.id);
        this.invitedUsers.push(attendee);
      });
    }
  }

  async manageCheckboxEvent(employee) {
    if (this.invitedUsers.includes(employee)) {
      this.invitedUsers = this.invitedUsers.filter(el => {
        return el.id !== employee.id;
      });
      this.attendeeIds = this.attendeeIds.filter(el => {
        return el !== employee.id;
      });
    } else {
      this.attendeeIds.push(employee.id);
      this.invitedUsers.push(employee);
    }
  }

  async searchEmployees() {
    this.isLoading = true;
    const accessToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
    axios.post(`${config.API_URL}/live-chat/search-employees`, {
      employee_code: this.employeeCode,
      first_name: this.firstName,
      last_name: this.lastName
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(res => {
      this.isLoading = false;
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      } else {
        this.hasSearched = true;
        data.users.forEach(user => {
          if (this.attendeeIds.includes(user.id)) {
            user.is_checked = true;
          }
        });
        this.employees = data.users;
      }
    }).catch(error => {
      this.isLoading = false;
      console.log(error);
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  dismissModal() {
    this.modalCtrl.dismiss({
      attendees: this.invitedUsers
    });
  }

  ngOnInit() {
  }

}
