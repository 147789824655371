import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import config from 'src/config';
import axios from 'axios';
import { NavParamsService } from 'src/app/nav-params.service';

@Component({
  selector: 'app-upload-file-modal',
  templateUrl: './upload-file-modal.page.html',
  styleUrls: ['./upload-file-modal.page.scss'],
})
export class UploadFileModalPage implements OnInit {

  public isLoading = false;
  public uploadFileMessage = '';
  public chatRoomUuid = '';

  constructor(
    public modalCtrl: ModalController,
    public storage: Storage,
    public alertController: AlertController,
    public navParams: NavParamsService
  ) {
    this.chatRoomUuid = this.navParams.getNavParams().chat_room_uuid;
  }

  closeModal() {
    this.modalCtrl.dismiss({
      success: false
    });
  }

  async uploadFile() {
    this.isLoading = true;

    const formData = new FormData();

    const image1: any = document.getElementById('fileUploadInput');
    if (image1 && image1.files && image1.files[0]) {
      const file = image1.files[0];
      formData.append('file', file);
    }

    formData.append('message', this.uploadFileMessage);
    formData.append('uuid', this.chatRoomUuid);

    const storageToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);

    axios.post(`${config.API_URL}/live-chat/upload-file`, formData, {
      headers: {
        Authorization: `Bearer ${storageToken}`
      }
    }).then(res => {
      this.isLoading = false;
      const data = res.data;
      if (data.error) {
        this.alertController.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      } else {
        this.modalCtrl.dismiss({
          success: true,
          message: data.message
        });
      }
    }).catch(error => {
      console.log(error);
      this.isLoading = false;
      this.alertController.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  ngOnInit() {
  }

}
