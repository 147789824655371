import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';

import { ChatRoomInfoPageRoutingModule } from './chat-room-info-routing.module';

import { ChatRoomInfoPage } from './chat-room-info.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    ChatRoomInfoPageRoutingModule
  ],
  declarations: [ChatRoomInfoPage]
})
export class ChatRoomInfoPageModule {}
