import { Component, OnInit } from '@angular/core';
import { ModalController, NavController, AlertController, ActionSheetController, LoadingController } from '@ionic/angular';
import { NavParamsService } from 'src/app/nav-params.service';
import config from 'src/config';
import { Storage } from '@ionic/storage';
import axios from 'axios';

@Component({
  selector: 'app-chat-room-info',
  templateUrl: './chat-room-info.page.html',
  styleUrls: ['./chat-room-info.page.scss'],
})
export class ChatRoomInfoPage implements OnInit {

  public isUpdating = false;
  public isLoading = false;
  public chatRoom: any = {};
  public groupName = '';
  public loadingUsersInfo = true;
  public employees = [];
  public invitableEmployees = [];
  public currentEmployees = [];
  public currentEmployeesObjects = [];
  public employeeCode = '';
  public firstName = '';
  public lastName = '';
  public hasSearched = false;
  public adminIds = [];
  public isAdmin = false;

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParamsService,
    public navCtrl: NavController,
    public storage: Storage,
    public alertCtrl: AlertController,
    public actionSheetCtrl: ActionSheetController,
    public loadingCtrl: LoadingController
  ) { }

  async updateGroupChat() {
    if (this.groupName === null || this.groupName === undefined || this.groupName.length <= 0) {
      this.alertCtrl.create({
        header: 'Alert',
        message: 'Please enter the group name!',
        buttons: ['Ok']
      }).then(alert => {
        alert.present().then(() => {
          this.navCtrl.pop();
        });
      });
      return;
    }

    if (this.currentEmployees.length <= 0) {
      this.alertCtrl.create({
        header: 'Alert',
        message: 'Please select at least one participant!',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
      return;
    }

    this.isUpdating = true;
    const accessToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
    axios.post(`${config.API_URL}/live-chat/update-group-chat`, {
      name: this.groupName,
      uuid: this.chatRoom.uuid,
      participants: this.currentEmployees
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(res => {
      this.isUpdating = false;
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      } else {
        this.alertCtrl.create({
          header: 'Success',
          message: 'Successfully updated group chat!',
          buttons: ['Ok']
        }).then(alert => {
          // Reset search state
          this.employeeCode = '';
          this.firstName = '';
          this.lastName = '';
          this.hasSearched = false;
          this.invitableEmployees = [];

          alert.present().then(() => {
            this.loadCurrentEmployees();
          });
        });
      }
    }).catch(error => {
      console.log(error);
      this.isUpdating = false;
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  async searchEmployees() {
    this.invitableEmployees = [];
    this.isLoading = true;
    const accessToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
    axios.post(`${config.API_URL}/live-chat/search-employees`, {
      employee_code: this.employeeCode,
      first_name: this.firstName,
      last_name: this.lastName
    }, {
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then(res => {
      this.isLoading = false;
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      } else {
        this.hasSearched = true;
        data.users.forEach(user => {
          if (!this.currentEmployees.includes(user.id)) {
            this.invitableEmployees.push(user);
          }
        });
      }
    }).catch(error => {
      this.isLoading = false;
      console.log(error);
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  manageCheckboxEvent(employeeId) {
    if (this.currentEmployees.includes(employeeId)) {
      this.currentEmployees = this.currentEmployees.filter(el => {
        return el !== employeeId;
      });
    } else {
      this.currentEmployees.push(employeeId);
    }
  }

  async longPressEmployee(employee) {
    if (!this.isAdmin) {
      return false;
    }

    if (this.adminIds.includes(employee.id)) {
      await this.showAdminActionSheet(employee);
    } else {
      await this.showRegularParticipantActionSheet(employee);
    }
  }

  async removeUser(employee) {
    const loader = await this.loadingCtrl.create({
      message: 'Loading...'
    });
    loader.present();

    const storageToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);

    axios.post(`${config.API_URL}/live-chat/remove-attendee`, {
      user_id: employee.id,
      uuid: this.chatRoom.uuid
    }, {
      headers: {
        Authorization: `Bearer ${storageToken}`
      }
    }).then(res => {
      loader.dismiss();
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present().then(() => {
            this.loadCurrentEmployees();
          });
        });
      } else {
        this.alertCtrl.create({
          header: 'Success',
          message: 'Successfully removed user!',
          buttons: ['Ok']
        }).then(alert => {
          alert.present().then(() => {
            this.loadCurrentEmployees();
          });
        });
      }
    }).catch(error => {
      console.log(error);
      loader.dismiss();
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  async removeAdminRole(employee) {
    const loader = await this.loadingCtrl.create({
      message: 'Revoking admin role...'
    });
    loader.present();

    const storageToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
    axios.post(`${config.API_URL}/live-chat/revoke-admin-access-from-attendee`, {
      user_id: employee.id,
      uuid: this.chatRoom.uuid
    }, {
      headers: {
        Authorization: `Bearer ${storageToken}`
      }
    }).then(res => {
      loader.dismiss();
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present().then(() => {
            this.loadCurrentEmployees();
          });
        });
      } else {
        this.alertCtrl.create({
          header: 'Success',
          message: 'Successfully revoked admin role from user!',
          buttons: ['Ok']
        }).then(alert => {
          alert.present().then(() => {
            this.loadCurrentEmployees();
          });
        });
      }
    }).catch(error => {
      console.log(error);
      loader.dismiss();
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  async giveAdminRole(employee) {
    const loader = await this.loadingCtrl.create({
      message: 'Giving admin role...'
    });
    loader.present();

    const storageToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
    axios.post(`${config.API_URL}/live-chat/grant-admin-access-to-attendee`, {
      user_id: employee.id,
      uuid: this.chatRoom.uuid
    }, {
      headers: {
        Authorization: `Bearer ${storageToken}`
      }
    }).then(res => {
      loader.dismiss();
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present().then(() => {
            this.loadCurrentEmployees();
          });
        });
      } else {
        this.alertCtrl.create({
          header: 'Success',
          message: 'Successfully gave admin role to user!',
          buttons: ['Ok']
        }).then(alert => {
          alert.present().then(() => {
            this.loadCurrentEmployees();
          });
        });
      }
    }).catch(error => {
      console.log(error);
      loader.dismiss();
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please check your internet connection and try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  async showAdminActionSheet(employee) {
    this.actionSheetCtrl.create({
      header: 'Actions',
      buttons: [
        {
          text: 'Remove Admin Role',
          handler: () => {
            this.removeAdminRole(employee);
          }
        },
        {
          text: 'Remove',
          role: 'destructive',
          handler: () => {
            this.removeUser(employee);
          }
        },
        {
          text: 'Cancel',
        }
      ]
    }).then(actionSheet => {
      actionSheet.present();
    });
  }

  async showRegularParticipantActionSheet(employee) {
    this.actionSheetCtrl.create({
      header: 'Actions',
      buttons: [
        {
          text: 'Give Admin Role',
          handler: () => {
            this.giveAdminRole(employee);
          }
        },
        {
          text: 'Remove',
          role: 'destructive',
          handler: () => {
            this.removeUser(employee);
          }
        },
        {
          text: 'Cancel',
        }
      ]
    }).then(actionSheet => {
      actionSheet.present();
    });
  }

  async loadCurrentEmployees() {
    this.loadingUsersInfo = true;
    const storageToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
    axios.post(`${config.API_URL}/live-chat/get-attendees`, {
      uuid: this.chatRoom.uuid
    }, {
      headers: {
        Authorization: `Bearer ${storageToken}`
      }
    }).then(res => {
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      } else {
        this.currentEmployees = [];
        this.loadingUsersInfo = false;
        data.participants.forEach(participant => {
          participant.is_checked = true;
          this.currentEmployees.push(participant.user_id);
        });
        this.adminIds = data.admin_ids;

        data.users.forEach(user => {
          if (this.adminIds.includes(user.id)) {
            user.is_admin = true;
          } else {
            user.is_admin = false;
          }
        });

        this.employees = data.users;
        this.isAdmin = data.is_admin;
      }
    }).catch(error => {
      console.log(error);
      this.alertCtrl.create({
        header: 'Error',
        // tslint:disable-next-line: max-line-length
        message: 'Sorry, something went wrong getting the participants for this group chat. Please check your internet connection and try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  ngOnInit() {
    this.chatRoom = this.navParams.getNavParams().chat_room;
    this.groupName = this.navParams.getNavParams().chat_room.individual_name;
    this.loadCurrentEmployees();
  }

}
