import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./change-password/change-password.module').then( m => m.ChangePasswordPageModule )
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'ess',
    loadChildren: () => import('./ess/ess.module').then( m => m.EssPageModule)
  },
  {
    path: 'operations',
    loadChildren: () => import('./operations/operations.module').then( m => m.OperationsPageModule)
  },
  {
    path: 'bookings',
    loadChildren: () => import('./bookings/bookings.module').then( m => m.BookingsPageModule)
  },
  {
    path: 'live-chat',
    loadChildren: () => import('./live-chat/live-chat.module').then( m => m.LiveChatPageModule)
  },
  {
    path: 'helpdesk',
    loadChildren: () => import('./helpdesk/helpdesk.module').then( m => m.HelpdeskPageModule)
  },
  {
    path: 'business-reporting',
    loadChildren: () => import('./business-reporting/business-reporting.module').then( m => m.BusinessReportingPageModule)
  },
  {
    path: 'e-business-card',
    loadChildren: () => import('./e-business-card/e-business-card.module').then( m => m.EBusinessCardPageModule)
  },
  {
    path: 'self-ordering',
    loadChildren: () => import('./self-ordering/self-ordering.module').then( m => m.SelfOrderingPageModule)
  },
  {
    path: 'store-audits',
    loadChildren: () => import('./store-audits/store-audits.module').then( m => m.StoreAuditsPageModule)
  },
  {
    path: 'wrapper',
    loadChildren: () => import('./wrapper/wrapper.module').then( m => m.WrapperPageModule)
  },
  {
    path: 'homepage-feed',
    loadChildren: () => import('./homepage-feed/homepage-feed.module').then( m => m.HomepageFeedPageModule)
  },
  {
    path: 'innovation-center',
    loadChildren: () => import('./innovation-center/innovation-center.module').then( m => m.InnovationCenterPageModule)
  },
  {
    path: 'view-other-employee',
    loadChildren: () => import('./view-other-employee/view-other-employee.module').then( m => m.ViewOtherEmployeePageModule)
  },
  {
    path: 'lookup-employees',
    loadChildren: () => import('./lookup-employees/lookup-employees.module').then( m => m.LookupEmployeesPageModule)
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
