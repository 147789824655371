import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-more',
  templateUrl: './more.page.html',
  styleUrls: ['./more.page.scss'],
})
export class MorePage implements OnInit {

  constructor(
    public modalCtrl: ModalController
  ) { }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  openPage(pageToOpen) {
    this.modalCtrl.dismiss({
      movedPage: true,
      page: pageToOpen
    });
  }

  ngOnInit() {
  }

}
