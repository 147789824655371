let config = {
    IFRAME_URL : "https://aeonfamily.aeonsolutionscenter.com/iframes",
    WEBSOCKET_URL: 'https://ws.family.aeonsolutionscenter.com',
    API_URL: 'https://aeonfamily.aeonsolutionscenter.com/api',
    SITE_URL: 'https://aeonfamily.aeonsolutionscenter.com',
    // API_URL: 'http://localhost:8000/api',
    // SITE_URL: 'http://localhost:8000',
    ACCESS_TOKEN_IDENTIFIER: 'AEONFamilyAccessToken',
    microservices: {
        chicken_website_iframe: 'https://chickensales.aeonsolutionscenter.com/iframes',
        manager_iframe: 'https://manager.aeonsolutionscenter.com/iframes',
    },
    ONE_SIGNAL_KEY: '72fb8dc8-2edd-4c1c-9636-2b505c0cfd40',
    SENDER_ID: '127208166601'
};
export default config;
