import { NgModule } from '@angular/core';
import { BrowserModule, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { AutosizeModule } from 'ngx-autosize';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

import { IonicStorageModule } from '@ionic/storage';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { Camera } from '@ionic-native/camera/ngx';
import { QRCodeModule } from 'angularx-qrcode';
import { InAppBrowser } from '@ionic-native/in-app-browser/ngx';
import { SQLite } from '@ionic-native/sqlite/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { CodePush } from '@ionic-native/code-push/ngx';
import { BarcodeScanner } from '@ionic-native/barcode-scanner/ngx';
import { CommentLikesPageModule } from './ess/forums/comment-likes/comment-likes.module';
import { CreateChatPageModule } from './live-chat/create-chat/create-chat.module';
import { UploadFileModalPageModule } from './live-chat/chat-room/upload-file-modal/upload-file-modal.module';
import { CreateGroupChatPageModule } from './live-chat/create-chat/create-group-chat/create-group-chat.module';
import { ChatRoomInfoPageModule } from './live-chat/chat-room/chat-room-info/chat-room-info.module';
import { MorePageModule } from './home/more/more.module';
// tslint:disable-next-line: max-line-length
import { InviteUsersPageModule } from './bookings/view-type/view-location/view-booking-object/create-booking/invite-users/invite-users.module';
import { LongPressDirective } from './directives/long-press.directive';
import { IonicGestureConfig } from '../ionic-gesture-config';
import { NgCalendarModule } from 'ionic2-calendar';
import { ViewEventPageModule } from './bookings/calendar/view-event/view-event.module';

// i18n
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

@NgModule({
  declarations: [AppComponent, LongPressDirective],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    IonicStorageModule.forRoot(),
    QRCodeModule,
    CommentLikesPageModule,
    CreateChatPageModule,
    AutosizeModule,
    UploadFileModalPageModule,
    CreateGroupChatPageModule,
    ChatRoomInfoPageModule,
    MorePageModule,
    NgCalendarModule,
    InviteUsersPageModule,
    ViewEventPageModule,
  ],
  providers: [
    StatusBar,
    SplashScreen,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HAMMER_GESTURE_CONFIG, useClass: IonicGestureConfig },
    Geolocation,
    Camera,
    InAppBrowser,
    SQLite,
    OneSignal,
    CodePush,
    BarcodeScanner
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
