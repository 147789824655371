import { Component, OnInit } from '@angular/core';
import { ModalController, AlertController, LoadingController } from '@ionic/angular';
import { NavParamsService } from 'src/app/nav-params.service';
import axios from 'axios';
import config from 'src/config';
import { Storage } from '@ionic/storage';
import * as moment from 'moment';
import { EventService } from 'src/app/event.service';

@Component({
  selector: 'app-view-event',
  templateUrl: './view-event.page.html',
  styleUrls: ['./view-event.page.scss'],
})
export class ViewEventPage implements OnInit {

  public isLoading = true;
  public bookingId;
  public booking;
  public bookable;
  public canEdit = false;
  public startTime: any = '';
  public endTime: any = '';

  constructor(
    public modalCtrl: ModalController,
    public navParams: NavParamsService,
    public storage: Storage,
    public alertCtrl: AlertController,
    public loadingCtrl: LoadingController,
    public events: EventService
  ) {
    this.bookingId = this.navParams.getNavParams().eventId;
  }

  async deleteBooking() {
    const storageToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);
    const loader = await this.loadingCtrl.create({
      message: 'Loading...'
    });
    loader.present();

    axios.post(`${config.API_URL}/bookings/booking/${this.bookingId}/delete`, {}, {
      headers: {
        Authorization: `Bearer ${storageToken}`
      }
    }).then(res => {
      loader.dismiss();
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      } else {
        this.alertCtrl.create({
          header: 'Success',
          message: 'Successfully deleted booking!',
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
        this.events.publish('events:calendar:refresh');
        this.modalCtrl.dismiss();
      }
    }).catch(error => {
      loader.dismiss();
      console.log(error);
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong deleting this booking. Please check your internet connection and try again.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  async delete() {
    this.alertCtrl.create({
      header: 'Confirm Action',
      message: 'Are you sure you want to delete this booking?',
      buttons: [
        {
          text: 'No',
        },
        {
          text: 'Yes',
          handler: () => {
            this.deleteBooking();
          }
        }
      ]
    }).then(alert => {
      alert.present();
    });
  }

  async getBooking() {
    const storageToken = await this.storage.get(config.ACCESS_TOKEN_IDENTIFIER);

    axios.get(`${config.API_URL}/bookings/booking/${this.bookingId}`, {
      headers: {
        Authorization: `Bearer ${storageToken}`
      }
    }).then(res => {
      const data = res.data;
      if (data.error) {
        this.alertCtrl.create({
          header: 'Error',
          message: data.error_message,
          buttons: ['Ok']
        }).then(alert => {
          alert.present();
        });
      } else {
        this.startTime = moment(data.booking.start_date_time).format('DD/MM/YYYY HH:mm');
        this.endTime = moment(data.booking.end_date_time).format('DD/MM/YYYY HH:mm');
        this.booking = data.booking;
        this.bookable = data.bookable;
        this.canEdit = data.can_edit;
        this.isLoading = false;
      }
    }).catch(error => {
      console.log(error);
      this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong loading this booking. Please try again later.',
        buttons: ['Ok']
      }).then(alert => {
        alert.present();
      });
    });
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {
    this.getBooking();
  }

}
