import { Component } from '@angular/core';

import { Platform, AlertController, LoadingController, NavController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { CodePush } from '@ionic-native/code-push/ngx';
import { Storage } from '@ionic/storage';
import { Plugins } from '@capacitor/core';

import { EventService } from './event.service';
import { TranslateService } from '@ngx-translate/core';

import axios from 'axios';
import config from '../config';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})

export class AppComponent {
  /**
   * Token name
   */
  TOKEN: string = config.ACCESS_TOKEN_IDENTIFIER;

  /**
   * Api Url
   */
  API_URL: string = config.API_URL;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private navCtrl: NavController,
    public events: EventService,
    private storage: Storage,
    private translate: TranslateService,
    private oneSignal: OneSignal,
    private codePush: CodePush
  ) {
    this.initializeApp();
  }

  initializeApp() {
    const { SplashScreen }  = Plugins;

    this.platform.ready().then(() => {
      // Start one signal
      this.oneSignal.startInit(config.ONE_SIGNAL_KEY, config.SENDER_ID);
      this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification);
      this.oneSignal.handleNotificationReceived().subscribe(notif => {
        // do something when notification is received
      });
      this.oneSignal.handleNotificationOpened().subscribe((notif) => {
        // do something when notification is opened
      });
      this.oneSignal.endInit();

      // Codepush in background
      const downloadProgress = (progress) => { console.log(`Downloaded ${progress.receivedBytes} of ${progress.totalBytes}`); };
      this.codePush.sync({}, downloadProgress).subscribe(syncStatus => {
        console.log(syncStatus);
      });

      this.statusBar.backgroundColorByHexString('#831162');
      this.statusBar.styleLightContent();
      this.splashScreen.hide();
      SplashScreen.hide();
    });

    this.storage.get('language').then(language => {
      if (language === undefined || language === null || language.length <= 0) {
        language = 'en';
      }
      this.translate.setDefaultLang(language);
    });

    this.events.subscribe('app:changed_language', data => {
      const language = data.language;
      this.storage.set('language', language).then(() => {
        this.translate.setDefaultLang(language);
      });
    });

    this.events.subscribe('user:login', () => {
      this.handleLogin();
    });

    this.events.subscribe('user:logout', () => {
      this.doLogout();
    });

    this.storage.get(this.TOKEN).then((accessToken) => {
      // tslint:disable-next-line: triple-equals
      if (accessToken != null && accessToken != undefined && accessToken.length > 0) {
        this.events.publish('user:login');
      } else {
        this.events.publish('user:logout');
      }
    });

  }

  /**
   * Redirects the user to the logged in home page when
   * the "user:login" event is transmitted
   */
  async handleLogin() {
    const loader = await this.loadingCtrl.create({
      message: 'Loading...'
    });

    await loader.present();
    const accessToken = await this.storage.get(this.TOKEN);
    /**
     * check the access token. Logged out the user if the access token is empty
     */
    if (accessToken == null || accessToken === undefined || accessToken.length <= 0) {
      loader.dismiss();
      this.events.publish('user:logout');
      return false;
    }

    axios.get(`${this.API_URL}/auth/user-data`, {
      headers: {
        Authorization: 'Bearer ' + accessToken
      }
    }).then(async (response) => {
      const data = response.data;
      if (data.error) {
        if (data.error_code === 401 || data.error_code === '401') {
          this.events.publish('user:logout');
        } else {
          const alert = await this.alertCtrl.create({
            header: 'Error',
            message : data.message,
            buttons: ['Ok']
          });
          alert.present();
        }
        return false;
      }

      loader.dismiss();

      this.navCtrl.navigateRoot('/wrapper');

      // Upload Onesignal token
      this.oneSignal.getIds().then(onesignalData => {
        axios.post(`${config.API_URL}/onesignal/save-device`, {
          user_id: onesignalData.userId,
          push_token: onesignalData.pushToken
        }, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
          }
        }).then(onesignalResponse => {
          // Silent success
        }).catch(error => {
          // Silent fail
        });
      }).catch(error => {
        // Silent fail
      });
    }).catch(async (error) => {
      loader.dismiss();
      if (error.status === 401) {
        this.events.publish('user:logout');
        return false;
      }
      const alert = await this.alertCtrl.create({
        header: 'Error',
        message: 'Sorry, something went wrong. Please log in again.',
        buttons: ['Ok']
      });
      alert.present();
    });
  }

  /**
   * Logs the user out and resets them to the login page
   */
  async doLogout() {
    await this.storage.remove(this.TOKEN);
    this.navCtrl.navigateRoot('/login');
  }
}
